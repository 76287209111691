import React from 'react';
import { Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faSearch } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from '../../assets/scripts/dateLib';
import config from '../../config';

/**
 * Application list page
 * @param {object} argv object defining properties to use in this function
 * @param {Array<object>} argv.appList list of applications to iterate over
 * @param {string} argv.keyword word to filter list of users by
 * @param {Function} argv.onKeywordChange maintains the state of the keyword
 * @param {Function} argv.onSearch runs when the search button is clicked
 * @param {object} argv.currentUser currently logged in user
 * @returns {HTMLElement} html for application list block
 */
export function Apps({
    appList,
    keyword,
    onKeywordChange,
    onSearch,
    currentUser
}) {

    const isAllowed = (app, userId) => {
        return app.applicationMemberPerms.some(a => {
            return a.permissionLevel > 6 && a.userId === userId;
        });
    };

    const getSubscription = (app) => {
        return config.subscriptionTypes.find(s => {
            return s.id === Number(app.subscriptionType);
        });
    };

    return (
        <>
            <Row>
                <Col>
                    <h4><FontAwesomeIcon icon={faList} />&nbsp;Application List</h4>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form>
                        <Form.Group controlId='name'>
                            <InputGroup>
                                <Form.Control
                                    type='text'
                                    placeholder='Application Name'
                                    aria-describedby='inputGroupPrepend'
                                    onChange={onKeywordChange}
                                    value={keyword}
                                />
                                <Button
                                    variant='success'
                                    onClick={onSearch}
                                >
                                    <FontAwesomeIcon icon={faSearch} />&nbsp;Search
                                </Button>
                            </InputGroup>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col>
                    {appList && appList.map(a => {
                        return (
                            <Row key={a.applicationId} className='app-list'>
                                <Col className='d-none d-md-block'>
                                    <Row>
                                        <Col className='smaller-label'>name</Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <a href={`../app/${a.applicationId}`} id={a.applicationId}>{a.name}</a>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className='smaller-x2-label'>Subscription:</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <div>{getSubscription(a).name}</div>
                                        </Col>
                                        <Col md={6}>
                                            {currentUser && isAllowed(a, currentUser.Username) ?
                                                <div>${a.subscriptionPrice}/month</div>
                                                :
                                                ''
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <div className='smaller-x2-label'>Monthly Posts:</div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='smaller-x2-label'>Total Posts:</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            {a.currentMonthTotalShares}/{a.subscriptionMonthlyShareLimit}
                                        </Col>
                                        <Col md={6}>
                                            {a.totalShares}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className='smaller-x2-label'>Created:</div>
                                            <div className='smaller'>{formatDate(a.createdAt, 'dd-MMM-yyyy HH:mm')}</div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        );
                    })}
                </Col>
            </Row>
        </>
    );
}
